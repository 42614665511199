import * as React from 'react';

function SvgClock(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 40 41"
            fill="none"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 11.895a9 9 0 100 18 9 9 0 000-18zm-11 9c0-6.075 4.925-11 11-11s11 4.925 11 11-4.925 11-11 11-11-4.925-11-11z"
                fill="#8492A6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 13.895a1 1 0 011 1v5.382l3.447 1.724a1 1 0 01-.894 1.789l-4-2a1 1 0 01-.553-.895v-6a1 1 0 011-1z"
                fill="#8492A6"
            />
        </svg>
    );
}

export default SvgClock;
