import React from 'react';
import capitalize from 'capitalize';
import {
    required,
    Edit,
    FormDataConsumer,
    SelectInput,
    SimpleForm,
    DateInput,
} from 'react-admin';
import { PaymentMode, PaymentStatus, PaymentType } from '../constants';
import CurrencyInput from '../components/CurrencyInput';
import MoneyInput from '../components/MoneyInput';

const isRequired = required();

const PaymentEdit = props => (
    <Edit undoable={false} {...props}>
        <SimpleForm variant="outlined" redirect="show">
            <SelectInput
                source="type"
                label="Payment Type"
                validate={isRequired}
                choices={Object.keys(PaymentType).map(c => ({
                    id: c,
                    name: capitalize(c),
                }))}
            />

            <SelectInput
                source="mode"
                label="Payment Mode"
                validate={isRequired}
                choices={[
                    { id: 'cod', name: 'COD' },
                    { id: 'cheque', name: 'Cheque' },
                    { id: 'bank_deposit', name: 'Bank Deposit' },
                    { id: 'bank_transfer', name: 'Bank Transfer' },
                    { id: 'credits', name: 'Take from Credits' },
                    { id: 'deposit', name: 'Take from Deposit' },
                    { id: 'payfort_kema', name: 'Payfort-KEMA' },
                    { id: 'manual_tap', name: 'Manual TAP' },
                    { id: 'manual_payfort', name: 'Manual Payfort' },
                    { id: 'pos_sab', name: 'POS-SAB' },
                ]}
            />

            <CurrencyInput validate={isRequired} />

            <MoneyInput source="amount" validate={isRequired} />

            <SelectInput
                source="status"
                label="Payment Status"
                validate={isRequired}
                choices={Object.keys(PaymentStatus).map(c => ({
                    id: c,
                    name: c,
                }))}
            />

            <FormDataConsumer>
                {({ formData, ...rest }) =>
                    formData.status === 'complete' && (
                        <DateInput
                            source="paidAt"
                            label="Payment Date"
                            validate={isRequired}
                        />
                    )
                }
            </FormDataConsumer>
        </SimpleForm>
    </Edit>
);

export default PaymentEdit;
