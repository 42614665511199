import React from 'react';
import {
    List,
    Datagrid,
    DateField,
    TextField,
    Filter,
    TextInput,
    SelectInput,
    DateInput,
} from 'react-admin';
import UserReferenceField from '../components/UserReferenceField';
import Revision from './Revision';
import BoxitPagination from '../components/BoxitPagination';

const RevisionFilter = (props: any) => (
    <Filter variant="outlined" {...props}>
        <SelectInput
            source="model"
            choices={[
                { id: 'box', name: 'Box' },
                { id: 'storage', name: 'Storage' },
                { id: 'request', name: 'Request' },
                { id: 'job', name: 'Job' },
            ]}
            alwaysOn
        />
        <DateInput source="createdAt[$gte]" label="Created From" />
        <DateInput source="createdAt[$lte]" label="Created To" />
    </Filter>
);

const RevisionsList = (props: any) => {
    return (
        <List
            {...props}
            sort={{ field: 'createdAt', order: 'DESC' }}
            perPage={10}
            pagination={<BoxitPagination />}
            filters={<RevisionFilter />}
        >
            <Datagrid>
                <TextField source="serial" sortable={false} />
                <DateField
                    locales="en-IN"
                    label="Date"
                    source="createdAt"
                    showTime
                />
                <UserReferenceField label="User" link={false} />
                <TextField source="model" sortable={false} />
                <Revision />
            </Datagrid>
        </List>
    );
};

export default RevisionsList;
