import React, { useState } from 'react';
import { useNotify, useTranslate, useUpdate } from 'react-admin';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@material-ui/core';
import { Order } from '../../types';

type Props = {
    record: Order;
    disabled?: boolean;
};

const UpdateCurrentSizeButton: React.FC<Props> = ({ record, disabled }) => {
    const notify = useNotify();
    const translate = useTranslate();
    const [isOpen, setIsOpen] = useState(false);
    const [currentSize, setCurrentSize] = useState(record.currentSize);

    const [cancelOrder, { loading }] = useUpdate(
        'orders',
        record.id,
        { currentSize: parseFloat(currentSize) },
        record,
        {
            undoable: false,
            onSuccess: () => {
                notify('resources.orders.notification.update_success', 'info');
            },
            onFailure: () => {
                notify('resources.orders.notification.update_error', 'warning');
            },
        }
    );

    const handleCurrentSizeChange = event => {
        setCurrentSize(event.target.value.trim());
    };

    return (
        <span className="mx-2">
            <Button
                variant="outlined"
                color="secondary"
                size="small"
                disabled={disabled || loading}
                onClick={() => setIsOpen(true)}
            >
                Update Current Size
            </Button>

            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={isOpen}
                onClose={() => setIsOpen(false)}
            >
                <DialogTitle>Update Current Size</DialogTitle>

                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="currentSize"
                        label="Current Size"
                        type="number"
                        variant="outlined"
                        value={currentSize}
                        required
                        onChange={handleCurrentSizeChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setIsOpen(false)}>
                        {translate('action.close')}
                    </Button>

                    <Button
                        variant="contained"
                        color="secondary"
                        disabled={
                            loading || !Number.isFinite(parseFloat(currentSize))
                        }
                        onClick={() => {
                            cancelOrder();
                            setIsOpen(false);
                        }}
                    >
                        {translate('action.update')}
                    </Button>
                </DialogActions>
            </Dialog>
        </span>
    );
};

export default UpdateCurrentSizeButton;
